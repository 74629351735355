<template>
  <v-content class="booking">
    <div v-if="resultflg == 0 && item && reserve">
    <SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'booking_shops', query: { feature_id: 'booking'}}" ></SelectedShop>

    <h1 class="mt-8 text-center fontsize18 font-weight-bold">{{item.shop_name}}<br>{{item.title}}</h1>

    <div class="mb-5" style="width:30%; margin:0 auto;">
      <v-divider class="my-2 border_maincolor"></v-divider>
    </div>

    <!-- 予約内容 -->
    <!-- NEW_DEV-1591 cyber start -->
    <div class="px-4 body-2" v-if ="reserve.is_attention_for_temporary_booking == 1" v-html="item.attention_for_temporary_booking"></div>

    <div class="px-4">
      <v-divider class="my-5"></v-divider>
    </div>

    <!-- 注意事項 -->
      <div class="px-4 mb-8" v-if ="reserve.is_attention == 1">
        <h2 class="fontsize13 font-weight-bold" style="color:#9D9D9D;">注意事項</h2>
        <div class="mt-3 caption">
          {{item.description}}
        </div>
      <!-- NEW_DEV-1591 cyber end -->
      </div>

    <!-- 予約フォーム -->
    <div class="body-2 py-2 px-4 mb-1 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      予約内容 <v-chip x-small class="ml-2 maincolor-bg">必須</v-chip>
    </div>

    <ValidationObserver ref="observer">
    <div class="pa-4">
      <div v-if="reserve.is_menu == 1 && reserve.menu">
        <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;"><v-icon small>fas fa-book-open</v-icon> {{reserve.menu_name}}</h3>
        <ValidationProvider v-slot="{ errors }" :name="reserve.menu_name" rules="required">
        <v-select v-model="course" item-text="name" item-value="id" :items="reserve.menu" :color="init.color_code" :item-color="init.color_code" placeholder="選択してください" class="" :error-messages="errors" required></v-select>
        </ValidationProvider>
      </div>

      <v-row>
        <v-col cols="4">
          <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;"><v-icon small>far fa-calendar-alt</v-icon> 予約希望日</h3>
          <ValidationProvider v-slot="{ errors }" name="予約希望日" rules="required" ref="datepicker">
          <!-- NEW_DEV-1007 cyber start -->
          <datepicker :typeable="false" v-model="reserve_date" name="reserve_date" class="py-2" :language="ja" :format="DatePickerFormat" :disabled-dates="disabledDates" required wrapper-class="calanderwrap" :class="!errors[0] ? '' : 'datepicker_wrap_error'"></datepicker>
          <!-- NEW_DEV-1007 cyber end -->
          <div class="v-text-field__details" role="alert" v-if="errors[0]">
            <div class="v-messages theme--light error--text">
              <div class="v-messages__wrapper">
                <div class="v-messages__message" style="font-size:12px; color:#ff5252; line-height:12px;">{{errors[0]}}</div>
              </div>
            </div>
          </div>

          </ValidationProvider>
        </v-col>

        <v-col cols="4" v-if="reserve.is_accept_time == 1">
          <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;"><v-icon small>far fa-clock</v-icon> 時間</h3>
          <ValidationProvider v-slot="{ errors }" name="予約時間" rules="required">
            <v-select v-model="reserve_time" item-text="selectname" item-value="value" :items="available_times" :color="init.color_code" :item-color="init.color_code" placeholder="選択してください" class="pt-0" :error-messages="errors" required></v-select>
          </ValidationProvider>
        </v-col>

        <v-col cols="4" v-if="reserve.is_number == 1">
          <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;"><v-icon small>fas fa-users</v-icon> 予約人数</h3>
          <ValidationProvider v-slot="{ errors }" name="予約人数" :rules="reserve_num_rule">
          <!-- NEW_DEV-1014 cyber start -->
          <v-select v-model="reserve_num" item-text="selectname" item-value="value" :items="number_select" class="pt-0" placeholder="" single-line type="number" :error-messages="errors" required></v-select>
          <!-- NEW_DEV-1014 cyber end -->
          </ValidationProvider>
        </v-col>
      </v-row>

      <!-- NEW_DEV-1591 cyber start -->
      <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;"><v-icon small>fas fa-pen</v-icon> ご要望</h3>
      <ValidationProvider v-slot="{ errors }" name="ご要望" rules="max:3000">
      <!-- NEW_DEV-1021 cyber start -->
      <v-textarea v-model="reserve_memo" class="pt-0" clearable auto-grow rows="1" clear-icon="cancel" value="" :error-messages="errors"></v-textarea>
      <!-- NEW_DEV-1021 cyber end -->
      </ValidationProvider>
      <!-- NEW_DEV-1591 cyber end -->
    </div>

    <div class="body-2 py-2 px-4 mb-1 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">
      お客様情報 <v-chip x-small class="ml-2 maincolor-bg">必須</v-chip>
    </div>

    <div class="pa-4">
      <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;">氏名</h3>
      <!-- NEW_DEV-1023 cyber start -->
      <ValidationProvider v-slot="{ errors }" name="氏名" rules="required|max:256">
      <v-text-field v-model="reserve_name" class="pt-0 mb-4" placeholder="氏名を入力してください" single-line :error-messages="errors" required></v-text-field>
      <!-- NEW_DEV-1023 cyber end -->
      </ValidationProvider>

      <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;">メールアドレス</h3>
      <!-- NEW_DEV-1025 cyber start -->
      <ValidationProvider v-slot="{ errors }" name="メールアドレス" rules="required|max:256|email">
      <v-text-field v-model="reserve_email" class="pt-0 mb-4" placeholder="メールアドレスを入力してください" single-line type="email" :error-messages="errors" required></v-text-field>
      <!-- NEW_DEV-1025 cyber end -->
      </ValidationProvider>

      <h3 class="fontsize13 font-weight-bold" style="color:#9D9D9D;">電話番号(ハイフンなし)</h3>
      <ValidationProvider v-slot="{ errors }" name="電話番号" :rules="{required: true, regex: /^(0{1}\d{9,10})$/ }">
      <v-text-field v-model="reserve_tel" class="pt-0 mb-4" placeholder="電話番号を入力してください" single-line type="tel" :error-messages="errors" required></v-text-field>
      </ValidationProvider>
    </div>
    </ValidationObserver>

    <div class="px-4 text-center">
      <v-btn rounded class="maincolor-bg default_button2 title" @click="regist()">予約を申し込む</v-btn>
      <p class="caption mt-4">予約申請が完了すると<router-link to="/info/rule">利用規約</router-link>に同意したとみなします。</p>
    </div>

    </div>

    <div v-if="resultflg == 1">
      <div class="text-center mt-12 mb-8">
        <svg class="svg_maincolor" xmlns="http://www.w3.org/2000/svg" width="30.459" height="28" viewBox="0 0 30.459 28"><path d="M296.1,1808.11v10.767H274.138V1797.1H296.1v2.209l3.138-3.11v-2.212H271v28h28.231V1805Zm3.146-9.718-12.2,12.1-6.654-6.6-2.218,2.2,8.872,8.8,14.417-14.3Z" transform="translate(-271 -1793.991)" fill="#fff" fill-rule="evenodd"/></svg>
        <p class="mt-6">予約の申し込みが完了しました。</p>
      </div>

      <div class="px-4">
        <v-divider class="mb-8"></v-divider>
        <h3 class="fontsize13 mb-3">予約内容</h3>

        <v-row class="lh13">
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            予約店舗
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;">
            {{item.shop_name}}
          </v-col>
          <v-col v-if="reserve.is_menu == 1 && reserve.menu" cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            ご希望コース
          </v-col>
          <v-col v-if="reserve.is_menu == 1 && reserve.menu" cols="9" class="fontsize13" style="color: #333;">
            {{result_menu_name}}
          </v-col>
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;" v-if="result_reserve_date">
            予約希望日
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;" v-if="result_reserve_date">
            {{result_reserve_date}}
          </v-col>
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            時間
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;">
            {{reserve_time}}
          </v-col>
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;" v-if="reserve_num">
            予約人数
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;" v-if="reserve_num">
            {{reserve_num}}名
          </v-col>
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            ご要望
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;">
            {{reserve_memo}}
          </v-col>
        </v-row>

        <v-divider class="my-8"></v-divider>
        <h3 class="fontsize13 mb-3">お客様情報</h3>

        <v-row class="lh13">
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            氏名
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;">
            {{reserve_name}}
          </v-col>
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            メールアドレス
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;">
            {{reserve_email}}
          </v-col>
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            電話番号
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;">
            {{reserve_tel}}
          </v-col>
          <v-col cols="3" class="fontsize11 font-weight-bold" style="color: #9D9D9D;">
            注意事項
          </v-col>
          <v-col cols="9" class="fontsize13" style="color: #333;">
            {{item.attention_for_temporary_booking}}
          </v-col>
        </v-row>

        <v-divider class="my-8"></v-divider>

        <div class="text-center my-12">
          <v-btn rounded class="maincolor-bg default_button2 title" @click="toTop()">アプリトップへ戻る</v-btn>
        </div>
      </div>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </v-content>

</template>

<script>
import SelectedShop from '@/components/selected_shop.vue'
import moment from "moment"
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale"
import { required, regex, between, email, min_value, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})

extend('email', {
  ...email,
  message: '正しいメールアドレス形式ではありません',
})

extend('between', {
  ...between,
  message: '{_field_} は{min}〜{max}にしてください。',
})

extend('min_value', {
  ...min_value,
  message: '{_field_} は{min}〜にしてください。',
})

extend('regex', {
  ...regex,
  message: '{_field_} は正しい形式で入力してください。',
})

extend('max', {
  ...max,
  // NEW_DEV-1023 cyber start
  message: '{_field_} は{length}文字以内で入力してください。',
  // NEW_DEV-1023 cyber end
})
export default {
  components: {
    SelectedShop,
    Datepicker,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    course: null,
    isExpand: [0],
    loading: false,
    resultflg: 0,
    selected_shop: 'すべて',
    //NEW_DEV-1008 cyber start
    reserve_date: null,
    //NEW_DEV-1008 cyber end
    reserve_time: null,
    reserve_num: null,
    reserve_memo: null,
    reserve_name: null,
    reserve_email: null,
    reserve_tel: null,
    reserve_num_rule: null,
    result_menu_name: null,
    result_reserve_date: null,
    available_times: [],
    ja: ja,
    DatePickerFormat: "yyyy-MM-dd",
    disabledDates: {
      dates:[],
      to: new Date(Date.now() - 8640000)
    },
    lastAcceptDate: null,
    nowDate: new Date(),
    aboutDialog: false,
    rankDialog: false,
    maincolor: 'rgba(255,0,177,0.2)',
    reserve: null,
    item: null,
    // NEW_DEV-1014 cyber start
    number_select:[]
    // NEW_DEV-1014 cyber end
  }),
  async created () {
    this.getUrlParam()
    this.init = this.storageGet('*')

    var selected_shop = this.getUrlGetParam('selected_shop');
    var shop_id = this.getUrlGetParam('shop_id');

    if(!shop_id){
      shop_id = this.init.sid
    }

    if (selected_shop) {
        this.selected_shop = selected_shop
    } else {
        this.selected_shop = '全て'
    }

    var params = { params:{
      'shop_id': shop_id,
      'page': 1,
      //NEW_DEV-1563 cyber start
      'id':this.getUrlGetParam('id'),
      'preview':this.getUrlGetParam('preview')
    }}

    try{
      // 予約トップAPI
      var res = await this.apiCall('/user/booking', params, 'get');
      if (!res) return false;
      this.$set(this, 'item', res.data[0]);
      this.item.attention_for_temporary_booking = !this.item.attention_for_temporary_booking ? null : this.item.attention_for_temporary_booking.replace(/\n/g, '<br/>');

      // 予約詳細API ※APIできるまではスタブで
      var reserveReq = { params:{
        'id': this.item.id,
        'shop_id': shop_id,
        'preview':this.getUrlGetParam('preview')
        //NEW_DEV-1563 cyber end
      }}

      var reserveRes = await this.apiCall('/booking/form', reserveReq, 'get');
      if (!reserveRes) return false;
      this.$set(this, 'reserve', reserveRes);

      // 予約直前制限がある場合
      if (this.reserve.is_last_accept_time_hour && this.reserve.last_accept_time_hour) {
        let tmp = new Date(Date.now() + (this.reserve.last_accept_time_hour * (60 * 60 * 1000)));
        this.lastAcceptDate = tmp;
        this.disabledDates.to = new Date(tmp.getFullYear() + '/' + (tmp.getMonth() + 1) + '/' + tmp.getDate());
      }

      //休日がある場合
      if(this.reserve.is_holiday_date == 1){
        this.reserve.holiday_date.forEach((val) => {
            this.disabledDates.dates.push(new Date(val.start_date.replace(/-/g,"/")));
        });
        //休日期間
        //this.disabledDates.ranges = this.reserve.holiday_date.map((e) => ({from: new Date(e['start_date']), to: new Date(e['end_date']+' 23:59:59')}));
      }

      //曜日指定の休日がある場合
      if(this.reserve.is_holiday_dayweek == 1){
          let holidayDayweek = [];
          this.reserve.holiday_dayweek.forEach((val) => {
              holidayDayweek.push(val.holiday);
          });
          this.disabledDates.days = holidayDayweek;
      }

      //人数制限がある場合
      if(this.reserve.is_number == 1){
        // NEW_DEV-1014 cyber start
        for(let i=0; i <= this.reserve.number_max-this.reserve.number_min; i++){
          this.number_select.push(this.reserve.number_min+i)
        }
        // NEW_DEV-1014 cyber end
        this.reserve_num_rule = 'required|between:' + this.reserve.number_min + ',' + this.reserve.number_max
      }else{
        this.reserve_num_rule = 'required|min_value:1'
      }

      //予約可能時間がある場合
      if(this.reserve.is_accept_time == 1){
        this.setAvailableTimes();
      }else{
        this.reserve_num_rule = 'required|min_value:1'
      }
    } catch(e) {
      console.log(e);
      this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
  mounted () {

  },
  watch: {
    reserve_date: function (val) { //予約日を監視して、当日の場合は現在時刻以降の予約時間選択にする
      var today = new Date()
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      var Hour = today.getHours();
      var minutes = today.getMinutes() +5;
      var nowDate = year + '/' + month + '/' + day
      var calenderday = new Date(val);
      var calYear = calenderday.getFullYear();
      var calMonth = calenderday.getMonth() + 1;
      var calDay = calenderday.getDate();
      var calenderDate = calYear + '/' + calMonth + '/' + calDay
      if(nowDate == calenderDate){
        if(this.reserve.is_last_accept_time_hour == 1 && this.reserve.last_accept_time_hour > 0){
          Hour = Hour + this.reserve.last_accept_time_hour
        }
        minutes = Math.ceil(minutes / this.reserve.accept_time_interval) * this.reserve.accept_time_interval;
        this.setAvailableTimes(Hour, minutes);
      } else if (this.lastAcceptDate && this.lastAcceptDate.getTime() > (new Date(calenderDate)).getTime()) {
        if (this.reserve.is_accept_time == 1 && this.reserve.accept_start_time_hour > this.lastAcceptDate.getHours()) {
          Hour = this.reserve.accept_start_time_hour;
          minutes = Math.ceil(this.accept_start_time_min / this.reserve.accept_time_interval) * this.reserve.accept_time_interval;
        } else {
          Hour = this.lastAcceptDate.getHours();
          minutes = Math.ceil(this.lastAcceptDate.getMinutes() / this.reserve.accept_time_interval) * this.reserve.accept_time_interval;
        }
        console.log(Hour, minutes);
        this.setAvailableTimes(Hour, minutes);
      } else {
        this.setAvailableTimes();
      }
    }
  },
  methods: {
    setAvailableTimes: function(acceptstartTime = null, acceptstartMinits = null) {
        var start_time_hour;
        var start_time_min;
        this.available_times = [];
        // 予約時間コード From
        if(acceptstartTime && acceptstartMinits){
          start_time_hour = acceptstartTime;
          start_time_min = acceptstartMinits;
        } else {
          start_time_hour = this.reserve.accept_start_time_hour;
          start_time_min = this.reserve.accept_start_time_min;
        }
        const end_time_hour = this.reserve.accept_end_time_hour;
        const end_time_min = this.reserve.accept_end_time_min;

        if(start_time_hour >= end_time_hour) {
          this.available_times.push({"value": "", "selectname": '予約可能時間がありません'});
        }else{
          let time_interval = null;
          if(this.reserve.is_accept_time_interval == 1){
            time_interval = this.reserve.accept_time_interval;
          }else{
            time_interval = 10;
          }
          var time_value = moment.duration(String(start_time_hour) + ':' + String(start_time_min),  "HH:mm");
          let h = Number(time_value.hours())
          let m = Number(time_value.minutes())
          let lastHour = false
          let firstHour = false
          while ((!lastHour || !firstHour) && (h < end_time_hour || ( h == end_time_hour && m < end_time_min ))) {
              const hours = time_value.hours() < 10 ? "0" + time_value.hours() : time_value.hours();
              const minutes = time_value.minutes() < 10 ? "0" + time_value.minutes() : time_value.minutes();
              var time_str = hours + ":" + minutes
              if (!lastHour || (lastHour && hours != '00')) {
                this.available_times.push({"value": time_str, "selectname": time_str + '〜'})
                time_value.add(time_interval, "minutes")
              } else {
                firstHour = true
              }
              h = Number(hours)
              m = Number(minutes)
              if (h == 23) {
                lastHour = true
              }
          }
        }
    },
    regist: function(){
      this.$refs.observer.validate().then(async result => {
        if (result) {
          try {
            //loadingを表示
            this.loading = true

            //日付をフォーマット
            var year = this.reserve_date.getFullYear();
            var month = this.reserve_date.getMonth() + 1;
            var day = this.reserve_date.getDate();
            var formatReserveDate = year + '-' + month + '-' + day
            var dayName = '日月火水木金土'[this.reserve_date.getDay()]
            this.result_reserve_date = year + '年' + month + '月' + day + '日（' + dayName + '）'

            //直前の受付時間の制限があった場合
            if(this.reserve.is_last_accept_time_hour == 1 && this.reserve.last_accept_time_hour > 0){
              //現在日時を取得
              var Now = new Date()
              //現在時刻より制限時間を設定
              Now.setHours(Now.getHours() + this.reserve.last_accept_time_hour);
              //予約時間をフォーマット
              var reserveDateTime = year + '/' + month + '/' + day + " " + this.reserve_time + ":00"
              var reserveDateTimeFormat = new Date(reserveDateTime);

              //現在時刻が予約受け付け制限を超えていた場合
              if(Now > reserveDateTimeFormat){
                this.loading = false
                this.callDialog('エラー', '現在時刻より'+this.reserve.last_accept_time_hour+'時間後の予約時間を設定してください。', 3);
                return false
              }
            }

            //送信されたメニュー名を抽出
            var findmenu = this.reserve.menu.find((v) => v.id === this.course)
            if(findmenu){
              this.result_menu_name = findmenu.name
            }else{
              this.result_menu_name = null
            }


            // 予約登録API実行
            var req = {
              id: this.reserve.id,
              booking_date: formatReserveDate,
              booking_time: this.reserve_time,
              booking_people: this.reserve_num,
              booking_request: this.reserve_memo,
              name: this.reserve_name,
              email: this.reserve_email,
              tel: this.reserve_tel,
              shop_id: this.reserve.shop_id
            }

            if(this.course != null){
              req["menu_id"] = this.course
            }

            var res = await this.apiCall('/booking', req, 'post', 0);

            if (!res) {
              this.loading = false
              return false
            }

            this.resultflg = 1
            this.loading = false

            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });

          } catch(e) {
            this.loading = false
            this.callDialog('エラー', e, 3);
          }
        }
      });
    },
    async toTop() {
      try {
        this.loading = true
        //ルーティングAPI
        var routing_req = { params: {
          feature_id: 'top'
        }};
        var routing_res = await this.apiCall('/user/routing', routing_req, 'get');
        if (!routing_res) {
          this.loading = false
          return false
        }
        //console.log(this.routing)

        var tonative_req = {
            'destination': routing_res.destination,
            'feature_id': routing_res.feature_id,
            'screen_id': routing_res.screen_id,
            'transition_type': 2
          };

        if (!this.screenTransition(tonative_req)) {
          this.loading = false

          this.callDialog(
            "遷移エラー",
            "遷移情報が不正です。再度アクセスして下さい。",
            2
          );
        }else{
          this.loading = false;
        }
      } catch (e) {
        this.callDialog(
          "通信エラー",
          "ネットワークの状態が不安定です。再度お試しください",
          3
        );
      }
    }
  }
}
</script>

<style scoped>
.memotitlebar {
  background-color: #E4E4E4;
  color: #000;
  font-weight: bold;
}
.calanderwrap {
  margin-top: 4px;
}
.calanderwrap >>> div {
    border-bottom-color: rgba(0, 0, 0, 0.42);;
    border-bottom-style: solid;
    border-width: 0 0 thin 0;
}
.calanderwrap >>> input {
  width: 100%;
}
.lh13 {
  line-height: 18px;
}
.inputstyle {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.0071428571em !important;
    line-height: 1.375rem;
    font-family: "Roboto", sans-serif !important;
    transform: scale(0.8);
}
</style>

<style>
.datepicker_wrap_error > div {
  border-bottom-color: #ff5252 !important;
}
</style>
